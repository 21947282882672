@font-face {
  font-family: 'Arial Rounded MT Bold';
  src: local("Arial Rounded MT Bold"), url("./assets/fonts/Arial-Rounded-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arial Rounded MT Regular';
  src: local("Arial Rounded MT Regular"), url("./assets/fonts/Arial-Rounded-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


.main-container {
  max-width: 1600px;
}

h1,.arial-rounded-bold {
  font-family: 'Arial Rounded MT Bold', 'Times New Roman', Times, serif;
}
h2,h3,p,.arial {
  font-family: 'Arial Rounded MT Regular', 'Verdana', 'Geneva', 'Tahoma', sans-serif;
}