@import "../../assets/scss/variables";

.scrollarea {
    overflow-y: auto;
}

.view-container {
    height: calc(100vh - 74px);
}

.navbar-toggler:focus {
    box-shadow:unset !important;
}

.list-group-item.active {
    color: $text !important;
    border-color: rgba(0, 0, 0, 0.125) !important;
    background-color: #e9dedd !important;
}