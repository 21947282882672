@import "bootstrap/scss/functions";

$primary: #2f1910;
$secondary: #f2eceb;
$text: #2d2c32;

$font-family-base: "Arial Rounded MT Regular", sans-serif;

@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
