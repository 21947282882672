@import "../../assets/scss/variables";

.quote {
  padding: 3rem 2rem 1rem 2rem;
  position: relative;
  &::before {
    content: "“";
    color: $primary;
    font-size: 5rem;
    width: 100%;
    max-width: 3.75rem;
    line-height: 1;
    width: 100%;
    position: absolute;
    left: 0.9rem;
    top: 0.9rem;
  }
}
